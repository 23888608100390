import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import logoIcon from '../assets/svg/logo.svg';
import phoneIcon from '../assets/svg/phone.svg';
import searchIcon from '../assets/svg/search.svg';
import fbIcon from '../assets/svg/fb.svg';
import instIcon from '../assets/svg/inst.svg';
import twtIcon from '../assets/svg/twt.svg';
import ytIcon from '../assets/svg/yt.svg';
import liIcon from '../assets/svg/li.svg';
import menuIcon from '../assets/svg/bars.svg'
import arrowDown from '../assets/svg/arrow-down.svg'

import {
    Container
} from 'reactstrap';

const Header = () => {
    const [dropdown, setDropdown] = useState();
    const [mobileMenu, setMobileMenu] = useState();
    const [langDropdown, setLangDropdown] = useState();
    const [lang, setLang] = useState('en');

    return (
        <header className='header'>
            <Container>
                <div className='logo'>
                    <Link to="/">
                        <Isvg src={logoIcon} alt='logo' />
                    </Link>
                </div>

                <Isvg src={menuIcon} className='menu-icon' onClick={() => setMobileMenu(!mobileMenu)} />

                <div className='menu-container'>
                    <div className='menu-upper'>
                        <div className='phone'>
                            <Isvg src={phoneIcon} />
                            <a href="tel:+905382316165">+ 90 538 231 6165</a>
                        </div>
                        <div className='social'>
                            <span>Follow us:</span>
                            <ul className='social-list'>
                                <li>
                                    <a>
                                        <Isvg src={fbIcon} />
                                    </a>
                                </li>
                                <li>
                                    <a>
                                        <Isvg src={instIcon} />
                                    </a>
                                </li>
                                <li>
                                    <a>
                                        <Isvg src={twtIcon} />
                                    </a>
                                </li>
                                <li>
                                    <a>
                                        <Isvg src={ytIcon} />
                                    </a>
                                </li>
                                <li>
                                    <a>
                                        <Isvg src={liIcon} />
                                    </a>
                                </li>
                            </ul>
                            <div className='search'>
                                <Isvg src={searchIcon} />

                                {/*<div class={searchBar ? 'search-container open' : 'search-container'}>
                                        <div className="search-form">
                                            <input type="text" value={this.state.search} onKeyPress={this.handleKeyPress} onChange={(e) => {

                                                this.setState({ search: e.target.value })
                                            }} placeholder={"Pretražite sajt...".translate(this.props.lang)} />
                                            <button onClick={() => this.searchSubmit()}>
                                                <Isvg src={arrow} />
                                            </button>
                                        </div>
                                    </div>*/}
                            </div>
                        </div>
                    </div>
                    <div className='menu-lower'>
                        <ul className='menu'>
                            <li>
                                <a href='home'>Home</a>
                            </li>
                            <li /*className='with-dropdown'*/>
                                <a href="#about" /*onClick={() => setDropdown(!dropdown)}*/>About Us</a>
                                {/*<ul className={dropdown ? 'dropdown opened' : 'dropdown'}>
                                    <li>
                                        <Link to='/'>History</Link>
                                    </li>
                                    <li>
                                        <Link to='/'>Mission and Vision</Link>
                                    </li>
                                    <li>
                                        <Link to='/'>Company Structure</Link>
                                    </li>
                                </ul>*/}
                            </li>
                            <li>
                                <a href="#specializaiton">Our Presence</a>
                            </li>
                            <li>
                                <a href='#product'>Products</a>
                            </li>
                            <li>
                                <a href='#supporters'>Our Supporters</a>
                            </li>
                        </ul>
                        <div className='lang-container' onClick={() => setLangDropdown(!langDropdown)}>
                            <span className='lang'>{lang === 'en' ? 'ENG' : 'BS'}</span>
                            <ul className={langDropdown ? 'lang-dropdown open' : 'lang-dropdown'}>
                                <li onClick={() => setLang('bs')}>BS</li>
                                <li onClick={() => setLang('en')}>ENG</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>

            {mobileMenu && <div className={mobileMenu ? 'mobile-menu open' : 'mobile-menu'}>
                    <span className='close' onClick={() => setMobileMenu(false)}>x</span>

                    {/*<ul className='menu'>
                        <li>
                            <Link to='/'>Home</Link>
                        </li>
                        <li className='with-dropdown'>
                            <a onClick={() => setDropdown(!dropdown)}>About Us</a>
                            {/*<Isvg src={arrowDown} className='dropdown-arrow' />
                            <ul className={dropdown ? 'dropdown opened' : 'dropdown'}>
                                <li>
                                    <Link to='/'>History</Link>
                                </li>
                                <li>
                                    <Link to='/'>Mission and Vision</Link>
                                </li>
                                <li>
                                    <Link to='/'>Company Structure</Link>
                                </li>
                            </ul>
                            
                        </li>
                        <li>
                            <Link to='/'>Services</Link>
                        </li>
                        <li>
                            <Link to='/'>Gallery</Link>
                        </li>
                        <li>
                            <Link to='/'>News</Link>
                        </li>
                        <li>
                            <Link to='/'>Contact</Link>
                        </li>
                    </ul>*/}
                    <ul className='menu'>
                            <li>
                                <a onClick={() => setMobileMenu(false)} href="home">Home</a>
                            </li>
                            <li /*className='with-dropdown'*/>
                                <a onClick={() => setMobileMenu(false)} href="#about" /*onClick={() => setDropdown(!dropdown)}*/>About Us</a>
                                {/*<ul className={dropdown ? 'dropdown opened' : 'dropdown'}>
                                    <li>
                                        <Link to='/'>History</Link>
                                    </li>
                                    <li>
                                        <Link to='/'>Mission and Vision</Link>
                                    </li>
                                    <li>
                                        <Link to='/'>Company Structure</Link>
                                    </li>
                                </ul>*/}
                            </li>
                            <li>
                                <a onClick={() => setMobileMenu(false)} href="#specializaiton">Our Presence</a>
                            </li>
                            <li>
                                <a onClick={() => setMobileMenu(false)} href='#product'>Products</a>
                            </li>
                            <li>
                                <a onClick={() => setMobileMenu(false)} href='#supporters'>Our Supporters</a>
                            </li>
                        </ul>
                </div>}
        </header>
    );
}

export default Header;
