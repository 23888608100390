import React from "react";
import Isvg from 'react-inlinesvg';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { API_ENDPOINT } from '../constants';
import s1 from '../assets/img/s1.jpg';
import sp1 from '../assets/img/sp1.png';
import sp2 from '../assets/img/sp2.png';
import sp3 from '../assets/img/sp3.png';

import Button from './button';

const HomeSlider = () => {

    var dynamicHeight = typeof window !== 'undefined' ? window.innerWidth / 100 * 42 : 0;

    const sliderPagination = [
        {
            image: sp1,
            title: 'Oil Services'
        },
        {
            image: sp2,
            title: 'Gas Services'
        },
        {
            image: sp3,
            title: 'General Trading'
        },
    ]

    const settings = {
        customPaging: (index) => {
            return (
                <div className="slide-tab">
                    <div className="slide-tab-block">
                        <div className="slide-tab-block-container">
                            <div>
                                <img src={sliderPagination[index].image} className="slide-tab-image" />
                            </div>
                            <div className="slide-tab-content">
                                <h3>{sliderPagination[index].title}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10 * 1000
    };

    return (
        <div>
            <Slider {...settings}>
                <div className="home-slide" >

                    <div className="container">
                        <div className="home-slide-content">
                            <h1 data-title="Izgradnja">Oil and Refined Oil products</h1>
                            <h6>TOMAR has been set up under the Patronage of Highness Sheik h Abdullah bin Rashid Al Mualla III
                                and is involved in the physical trading of Crude Oil and Refined Oil products
                                from the Middle east and the Arabian gulf.</h6>
                        </div>
                    </div>

                    <img src={s1} className="slide-bg" />

                </div>
                <div className="home-slide" >

                    <div className="container">
                        <div className="home-slide-content">
                            <h1 data-title="Izgradnja">Oil and Refined Oil products</h1>
                            <h6>TOMAR has been set up under the Patronage of Highness Sheik h Abdullah bin Rashid Al Mualla III
                                and is involved in the physical trading of Crude Oil and Refined Oil products
                                from the Middle east and the Arabian gulf.</h6>
                        </div>
                    </div>

                    <img src={s1} className="slide-bg" />

                </div>

                <div className="home-slide" >

                    <div className="container">
                        <div className="home-slide-content">
                            <h1 data-title="Izgradnja">Oil and Refined Oil products</h1>
                            <h6>TOMAR has been set up under the Patronage of Highness Sheik h Abdullah bin Rashid Al Mualla III
                                and is involved in the physical trading of Crude Oil and Refined Oil products
                                from the Middle east and the Arabian gulf.</h6>
                        </div>
                    </div>

                    <img src={s1} className="slide-bg" />

                </div>
            </Slider>

        </div>
    );
}

export default HomeSlider;