import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

//import Text from '../components/forms/fields/text';

import { API_ENDPOINT } from '../constants';

import flogo from '../assets/svg/logo-dark.svg';
import locationIcon from '../assets/svg/f-location.svg';
import phoneIcon from '../assets/svg/f-phone.svg';
import faxIcon from '../assets/svg/f-fax.svg';
import mailIcon from '../assets/svg/f-mail.svg';

import fbIcon from '../assets/svg/f-fb.svg';
import instIcon from '../assets/svg/f-inst.svg';
import twtIcon from '../assets/svg/f-twt.svg';
import ytIcon from '../assets/svg/f-yt.svg';
import liIcon from '../assets/svg/f-li.svg';
import arrow from '../assets/svg/nsl-arrow.svg';


const Footer = () => {
    const [newsletter, setNewsletter] = useState();

    return (
        <footer className='footer'>

            <Container>

                <Row style={{ paddingBottom: 35 }}>
                    <Col lg="3">
                        <div className="footer-logo">
                            <Link to="/">
                                <Isvg src={flogo} />
                            </Link>
                        </div>
                    </Col>
                    <Col lg="4">
                        <div className="contact-widget">
                            <h5 className="widget-heading">CONTACT</h5>
                            <div className="contact-widget-block">
                                <Isvg src={locationIcon} />
                                <div className="contact-widget-block-content">
                                    <p>Office 502, Vision Tower, Business Bay
                                        <br />
                                        Dubai, United Arab Emirates
                                    </p>
                                </div>
                            </div>
                            <div className="contact-widget-block">
                                <Isvg src={phoneIcon} />
                                <div className="contact-widget-block-content">
                                    <a href="tel:+905382316165">+ 90 538 231 6165</a>
                                </div>
                            </div>
                            {/*
                            <div className="contact-widget-block">
                                <Isvg src={faxIcon} />
                                <div className="contact-widget-block-content">
                                    <a href="tel:+38755247884">+971 4 5503445</a>
                                </div>
                            </div> */}
                            <div className="contact-widget-block">
                                <Isvg src={mailIcon} className='mail-icon-f' />
                                <div className="contact-widget-block-content">
                                    <p>
                                        <a href="mailto: mvs@tomar.ae">mvs@tomar.ae</a> <br/>
                                        <a href="mailto: marinko@tomar.ae">marinko@tomar.ae</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg="5">
                        <div className="newsletter-widget">
                            <h5 className="widget-heading">NEWSLETTER</h5>
                            <p>Sign up to our newsletter to stay up to date with the latest news and updates from TOMAR.</p>
                            <div className="newsletter">
                                <div className="newsletter-container">
                                    <input placeholder="E-mail adress" value={newsletter} />
                                    <button onClick={() => this.sendNewsletter()}>
                                        <Isvg src={arrow} />
                                    </button>
                                </div>
                            </div>
                            <ul className="footer-social">
                                <li>
                                    <a href="https://ms-my.facebook.com/bijeljinaput.doo.7" target="_blank">
                                        <Isvg src={fbIcon} />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/bnput.roadconstruction/" target="_blank">
                                        <Isvg src={instIcon} />
                                    </a>
                                </li>
                                <li>
                                    <a href="" target="_blank">
                                        <Isvg src={twtIcon} />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UC8WPy5XYHeOCSBuKC-BvcTg" target="_blank">
                                        <Isvg src={ytIcon} />
                                    </a>
                                </li>
                                <li>
                                    <a href="" target="_blank">
                                        <Isvg src={liIcon} />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row className="footer-bottom">
                    <Col lg="12" sm="12">
                        <p>Copyright © TOMAR  - 2022. All Rights Reserved.</p>
                    </Col>
                </Row>
            </Container>

        </footer>
    )
}

export default Footer;