import React from 'react';
import {
    Container, Row, Col
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import aboutImage from '../assets/img/about.jpg';
import specialization1Icon from '../assets/img/spec1.jpg';
import specialization2Icon from '../assets/img/spec2.jpg';
import specialization3Icon from '../assets/img/spec3.jpg';
import promoImage from '../assets/img/promo.jpg';
import a1 from '../assets/img/a1.jpg';
import a2 from '../assets/img/a2.jpg';
import a3 from '../assets/img/a3.jpg';
import supportersImage from '../assets/img/supporters.jpg';

import Slider from '../components/slider';

const HomePage = () => {

    const articles = [
        {
            image: a1,
            title: 'News section first title for blog post',
            description: 'One year on from our ambition launch, Bernard looks back at the initial steps bp taken in our bpnetzero journey and...',
            date: '25.01.2021.  |  16:48 h'
        },
        {
            image: a2,
            title: 'News section first title for blog post',
            description: 'One year on from our ambition launch, Bernard looks back at the initial steps bp taken in our bpnetzero journey and...',
            date: '25.04.2021.  |  16:48 h'
        },
        {
            image: a3,
            title: 'News section first title for blog post',
            description: 'One year on from our ambition launch, Bernard looks back at the initial steps bp taken in our bpnetzero journey and...',
            date: '25.03.2021.  |  16:48 h'
        },
    ]

    return (
        <div className='home-page'>

            <section id="home" className='home'>
                <Slider />
            </section>

            <section id="about" className='about'>
                <Container>
                    <Row>
                        <Col lg="5" xs="12">
                            <div className='about-image'>
                                <img src={aboutImage} alt='about' />
                            </div>
                        </Col>
                        <Col lg="7" xs="12">
                            <div className='about-text'>
                                <h2 className='title title-dark'>About TOMAR</h2>
                                <p>Our focus is to supply South Asian refineries with crude oil and required
                                    volumes of 10 ppm gas oil and higher grades. The company is run by
                                    top industry experts which run high positions in Vitol Group and Shell
                                    International for over 25 years.
                                    <br /> <br />
                                    We also plan to register our Trading Company with leading refineries in
                                    the region with the aim of securing long term supply of Iraqi and Libyan
                                    grades.
                                    <br /> <br />
                                    The company is also seeking to supply Heavy Russian m100 high
                                    sulphur cracked fuel oil to the market of UAE for production of power
                                    during the whole year.</p>
                            </div>
                        </Col>
                    </Row>
                    {/*<Row>
                        <Col lg="4">
                            <div className='feature'>
                                <div className='feature-icon'>
                                    <Isvg src={feature1Icon} />
                                </div>
                                <div className='feature-content'>
                                    <h6>Our Plan</h6>
                                    <p>One year on from ambition
                                        launch, Bernard looks back
                                        at the initial steps bp has</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg="4">
                            <div className='feature'>
                                <div className='feature-icon'>
                                    <Isvg src={feature2Icon} />
                                </div>
                                <div className='feature-content'>
                                    <h6>Our Mission</h6>
                                    <p>One year on from ambition
                                        launch, Bernard looks back
                                        at the initial steps bp has</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg="4">
                            <div className='feature'>
                                <div className='feature-icon'>
                                    <Isvg src={feature3Icon} />
                                </div>
                                <div className='feature-content'>
                                    <h6>Our Supporters</h6>
                                    <p>One year on from ambition
                                        launch, Bernard looks back
                                        at the initial steps bp has</p>
                                </div>
                            </div>
                        </Col>
                    </Row>*/}
                </Container>
            </section>

            <section id="specializaiton" className='specialization'>
                <Container>
                    <Row>
                        <Col lg="6">
                            <div className='introduction'>
                                <h2 className='title title-dark'>Our Presence</h2>
                                <p>Headquartered in Dubai, TOMAR  has offices in Pakistan,
                                    Fujairah and is in the process of setting up in Sri Lanka and
                                    India.
                                    <br /> <br />
                                    The next step is to rent Oil Storage facilities in Hamriah UAE
                                    and Karachi, Pakistan to be able to import and export oil
                                    products from the region.
                                    <br /> <br />
                                    Our Trading team has very strong relationships in Iraq,
                                    Abu Dhabi, Saudi Arabia, Oman and Libya and are able to
                                    negotiate preferable rates for both Spot and Long Term
                                    supplies from these locations.</p>
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className='specialization-cards'>
                                <div className='specialization-card s-card-1'>
                                    <img src={specialization1Icon} className='s-card-image' />
                                    <div className='s-card-title'>
                                        <h6>Oil Services</h6>
                                    </div>
                                </div>
                                <div className='specialization-card s-card-2'>
                                    <img src={specialization2Icon} className='s-card-image' />
                                    <div className='s-card-title'>
                                        <h6>Gas Services</h6>
                                    </div>
                                </div>
                                <div className='specialization-card s-card-3'>
                                    <img src={specialization3Icon} className='s-card-image' />
                                    <div className='s-card-title'>
                                        <h6>General Trading</h6>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section id="product" className='promo'>
                <Container>
                    <Row>
                        <Col lg="7">
                            <div className='promo-text'>
                                <h2 className='title title-light'>Products</h2>
                                <p>Crude Oil, Fuel Oil, LNG, Renewables, Oil Storage, Oil Product Processing</p>
                            </div>
                        </Col>
                        <Col lg="5">
                            <div className='promo-video'>
                                <img src={promoImage} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/*<section className='news'>
                <Container>
                    <Row>
                        <Col lg="6">
                            <div className='news-title'>
                                <h2>Latest news</h2>
                                <p>One year on from our ambition launch, Bernard looks back</p>
                            </div>
                        </Col>
                        <Col lg="6" className='push-right'>
                            <Button className='button-primary-dark' dataType={'transparent'} arrow={true}>ALL ARTICLES</Button>
                        </Col>
                    </Row>
                    <Row className='articles-row'>
                        {articles && articles.map((article, idx) => {
                            return (
                                <Col lg="4">
                                    <Article
                                        key={idx}
                                        image={article.image}
                                        title={article.title}
                                        description={article.description}
                                        date={article.date}
                                    />
                                </Col>
                            )
                        })}

                    </Row>
                </Container>
            </section>*/}

            <section id="supporters" className='supporters'>
                <Container>
                    <Row>
                        <Col lg="6">
                            <div className='supporters-text'>
                                <h2 className='title title-dark'>Our Supporters</h2>
                                <p>We have the full support of his Highness Sheik h Abdullah bin Rashid Al
                                Mualla III with who has a vast network across in the UAE and across the
                                GCC countries that has allowed us to establish a strong presence
                                internationally and in UAE
                                <br /> <br />
                                The company also owns a commercial and trading licence required for
                                business in the UAE.
                                <br /> <br />
                                The company will also have access to the oil storage in Turkey through
                                our partners, Delta Star Rubis terminal.</p>
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className='supporters-image'>
                                <img src={supportersImage} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </div>
    )
}

export default HomePage;