import React from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";

import { HomePage } from 'pages';
import { Header, Footer } from 'components';

const App = () => {

  return (
    <div className='page'>
        <div className='page-content'>
          <Header />
          <Routes>
            <Route path='/' element={<HomePage />} />
          </Routes>
          <Footer />
        </div>
    </div>
  );
}

export default App;

